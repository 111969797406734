import React, { PureComponent } from 'react';

import styled from 'styled-components';

import Box from '../atoms/Box/Box';
import Circle from '../atoms/Circle/Circle';
import CheckoutPopup from '../molecules/CheckoutPopup';

import Cross2Icon from '@public/icons/cross2.svg';

const Cross = styled.div`
  text-align: right;
  margin-bottom: 0.5em;
  cursor: pointer;
  position: absolute;
  top: -3.5em;
  right: 0;
`;

const BoxWrapper = styled(Box)`
  width: 70vw;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
`;

type VideoPopupProps = {
  onClose: () => void;
  url: string;
};

class VideoPopup extends PureComponent<VideoPopupProps> {
  render() {
    const { onClose, url } = this.props;

    return (
      <CheckoutPopup onClose={onClose} zIndex={15}>
        <BoxWrapper>
          <Cross onClick={onClose}>
            <Circle backgroundColor="white" size={2.5}>
              <Cross2Icon />
            </Circle>
          </Cross>
          <Video src={url} autoPlay controls controlsList="nodownload" />
        </BoxWrapper>
      </CheckoutPopup>
    );
  }
}

export default VideoPopup;
