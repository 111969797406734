import React, { Fragment, PureComponent } from 'react';

import styled from 'styled-components';

import Box from '../atoms/Box/Box';
import Text from '../atoms/Text/Text';

import { Transform } from '@shared/enums/transform';

const TriangleWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BoxTriangle = styled(Box)`
  position: relative;
  width: fit-content;
  min-width: 65%;

  padding-left: 0.5em;
  padding-right: 1em;
  padding-bottom: 0.15em;
  background: #f0f0f0ff;

  &::after {
    content: '';
    position: absolute;
    top: -8px;
    right: 6px;
    height: 35px;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #ffffff;
    transform: rotate(270deg);
  }
`;

type CardSubscriptionOldPriceProps = {
  regPrice: string;
  offer: string;
};

class CardSubscriptionOldPrice extends PureComponent<CardSubscriptionOldPriceProps> {
  render() {
    return (
      <Fragment>
        <Box mb={0.125} id="price">
          <Text
            size={2}
            color="monument"
            lineHeight="normal"
            transform={Transform.Uppercase}
          >
            <del>{this.props.regPrice}</del>
          </Text>
        </Box>

        <Box pr={0.5} pl={0.5}>
          <TriangleWrapper mb={0.25}>
            <BoxTriangle>
              <Text
                size={0.75}
                color="monument"
                lineHeight="normal"
                transform={Transform.Uppercase}
              >
                {this.props.offer}
              </Text>
            </BoxTriangle>
          </TriangleWrapper>
        </Box>
      </Fragment>
    );
  }
}

export default CardSubscriptionOldPrice;
