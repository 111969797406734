import React, { PureComponent } from 'react';

import styled from 'styled-components';

import Text from '../atoms/Text/Text';

import { Colors } from '@shared/interfaces/themes';
import InfoIcon from '@public/icons/info.svg';
import { Transform } from '@shared/enums/transform';
import { breakpoint } from 'theme';

const Info = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  left: 2.125em;
  top: -4.75em;
  ${props => `
    background-color: ${props.theme.colors.cornflowerBlue};
    color: ${props.theme.colors.white};  
  `};
  box-shadow: 0 0 0.5em 0 ${({ theme }) => theme.transparentColors.black25};
  padding: 0.625em 0.875em;
  border-radius: 0.25em;
  font-weight: normal;
  text-transform: none;
  font-size: 1rem;
  min-width: 9.375em;

  :after {
    content: '';
    position: absolute;
    left: -0.75em;
    top: 48%;
    width: 0;
    height: 0;
    border-top: 0.5em solid transparent;
    border-bottom: 0.5em solid transparent;
    border-right: 0.75em solid ${props => props.theme.colors.cornflowerBlue};
    clear: both;
  }

  ${breakpoint.down('ltm')`
    left: auto;
    right: -1em;
    top: 2.125em;
    
    :after {
      left: auto;
      right: 1em;
      top: -1.25em;
      border-left: 0.5em solid transparent;
      border-right: 0.5em solid transparent;
      border-bottom: 0.75em solid ${props => props.theme.colors.cornflowerBlue};
    }
  `}
`;

const Title = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.875em;
  margin-bottom: 0.125em;
`;

const Platforms = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 0.875em;

  > li {
    line-height: 1.5;
  }
`;

const PositionBorder = styled.span`
  position: relative;
`;

type TooltipProps = {
  className?: string;
  title?: string;
  platforms: string;
  text: string;
  textColor: keyof Colors;
};

class Tooltip extends PureComponent<TooltipProps> {
  componentDidMount() {
    document.addEventListener('touchstart', () => {}); // required for the hover effect
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', () => {});
  }

  render() {
    return (
      <span className={this.props.className}>
        <Text
          size={0.75}
          color={this.props.textColor}
          lineHeight="normal"
          transform={Transform.Uppercase}
          weight="bold"
        >
          {this.props.text}
        </Text>
        <PositionBorder>
          <InfoIcon style={{ marginLeft: '0.25em', verticalAlign: 'middle' }} />
          <Info>
            <Title>{this.props.title}</Title>
            <Platforms>
              {JSON.parse(this.props.platforms.replace(/'/g, '"')).map(
                (platform, i) => (
                  <li key={i}>{platform}</li>
                )
              )}
            </Platforms>
          </Info>
        </PositionBorder>
      </span>
    );
  }
}

const StyledTooltip = styled(Tooltip)`
  :hover {
    ${Info} {
      display: block;
    }
  }
`;

export default StyledTooltip;
