import React, { Fragment } from 'react';

import styled from 'styled-components';

import { SalesState } from '@redux/reducers/sales/types';

import Box from '../atoms/Box/Box';
import ExternalLink from '../atoms/ExternalLink/ExternalLink';
import Icon from '../atoms/Icon';
import Link from '../atoms/Link/Link';
import Loader from '../atoms/Loader/Loader';
import Text from '../atoms/Text/Text';
import CardSubscriptionCircle from '../molecules/CardSubscriptionCircle';
import CardSubscriptionLabel from '../molecules/CardSubscriptionLabel';
import CardSubscriptionOldPrice from '../molecules/CardSubscriptionOldPrice';
import CardSubscriptionPrice from '../molecules/CardSubscriptionPrice';
import PurchaseType from '../molecules/PurchaseType';
import StatusButton from '../molecules/StatusButton';
import CardSubscriptionInfo from '@components/organisms/CardSubscriptionInfo';

import AnalyticsManager from '@utils/analyticsManager';
import { ROUTE } from '@utils/index';

import { Align } from '@shared/enums/align';
import { Color } from '@shared/enums/button-color';
import { Currencies } from '@shared/enums/currency';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { LONG_CURRENCIES, Status } from '@shared/markup/constants';
import { NEW_BUNDLE, OLD_BUNDLE } from '@shared/subscription/constants';
import { Option } from '@shared/interfaces/option';
import { Product } from '@shared/interfaces/product';
import { Sale } from '@shared/interfaces/sale';
import { Size } from '@shared/enums/button-size';
import { Subscription } from '@shared/interfaces/subscription';
import { _getSubscriptionIntervalDescription } from '@shared/subscription/functions';
import { _isPurchasedProducts } from '@shared/product/functions';
import { withTranslation } from 'i18n';

type CardSubscriptionProps = {
  option: Option;
  hasSale: boolean;
  stringRegPrice: string;
  stringSalePrice: string;
  hasPurchasedStudentLicense: boolean;
  currencyISO: Currencies;
  subscription: Subscription;
  isLoadingApps: boolean;
  application: Product;
  onAddToBasket: (subscription: Subscription[]) => void;
  onShowChangePurchasedSubscriptionPopup: () => void;
  sale: Sale | SalesState;
  hasCurrentSale: boolean;
  isAddingToBasket: boolean;
  isRecommendedSubscription: boolean;
  className: string;
  isLoadingCountry: boolean;
  t: I18nTranslation;
};

const CardSubscription = ({
  option,
  hasSale,
  stringRegPrice,
  stringSalePrice,
  hasPurchasedStudentLicense,
  currencyISO,
  subscription,
  isLoadingApps,
  application,
  onAddToBasket,
  onShowChangePurchasedSubscriptionPopup,
  sale,
  hasCurrentSale,
  isAddingToBasket,
  isRecommendedSubscription,
  className,
  isLoadingCountry,
  t: tProp,
}: CardSubscriptionProps) => {
  const t = key => tProp(`cardSubscription.${key}`);

  const textColor = 'cornflowerBlue';
  const currentBundle = subscription?.bundle?.toLowerCase();
  const isProLicense =
    hasPurchasedStudentLicense &&
    (currentBundle === OLD_BUNDLE.PRO || currentBundle === NEW_BUNDLE.PRO);
  const buyButtonTextWithDiscount = hasSale ? t('claimOffer') : t('buyNow');
  const buyButtonText = isProLicense ? t('upgrade') : buyButtonTextWithDiscount;
  const recommendedText = isProLicense ? t('recommended') : t('mostPopular');

  function handleClickSubscription() {
    const analyticsSegmentation = {
      name: isProLicense ? 'upgrade' : subscription?.bundle,
      price: hasSale ? stringSalePrice : stringRegPrice,
      currency: currencyISO,
    };
    const legacySegmentation = {
      name: isProLicense
        ? subscription?.bundle + ' - Upgrade-Professional'
        : subscription?.bundle,
      price: hasSale ? stringSalePrice : stringRegPrice,
      currency: currencyISO,
    };

    AnalyticsManager.google.pricingPage.addToCart(analyticsSegmentation);
    AnalyticsManager.googleLegacy.pricingPage.addToCart(legacySegmentation);

    if (isProLicense) {
      AnalyticsManager.adobeAnalytics.pricingPage.addUpgradeProfessional();
    } else {
      switch (currentBundle) {
        case OLD_BUNDLE.STUDENT:
        case NEW_BUNDLE.STUDENT:
          AnalyticsManager.adobeAnalytics.pricingPage.addBuyNowStudent();
          break;
        case OLD_BUNDLE.PRO:
        case NEW_BUNDLE.PRO:
          AnalyticsManager.adobeAnalytics.pricingPage.addBuyNowProfessional();
          break;
      }
    }

    const hasPurchasedSubscription = _isPurchasedProducts(
      application.subscriptions
    );
    switch (true) {
      case hasPurchasedSubscription:
        onShowChangePurchasedSubscriptionPopup();
        break;
      default:
        onAddToBasket([subscription]);
    }
  }

  function renderNotPurchasedView() {
    const size = LONG_CURRENCIES.includes(currencyISO) ? 2.5 : 4.25;
    const actualPrice = hasSale ? stringSalePrice : stringRegPrice;
    const interval = _getSubscriptionIntervalDescription(subscription.interval);

    return (
      <Box align={Align.Center}>
        {hasSale && (
          <CardSubscriptionOldPrice
            regPrice={stringRegPrice}
            offer={t('firstYearDiscount')}
          />
        )}
        {isLoadingCountry ? (
          <Icon name="isLoadingCountry" width={6} height={2} />
        ) : (
          <CardSubscriptionPrice
            color={textColor}
            size={size}
            price={actualPrice}
          />
        )}
        <PurchaseType color={textColor} text={tProp(`intervals.${interval}`)} />
        <StatusButtonExtend
          colorType={Color.FullGreen}
          sizeType={Size.Fifth}
          onClick={handleClickSubscription}
          status={isAddingToBasket ? Status.Loading : Status.Nothing}
          loaderSize={1.25}
        >
          {buyButtonText}
        </StatusButtonExtend>

        {option.link && (
          <Box mt={1}>
            <ExternalLink
              href={option.link}
              onClick={() => {
                AnalyticsManager.google.addItemView(
                  isProLicense ? 'upgrade' : subscription?.bundle
                );
              }}
            >
              <Text color="turquoise" weight={600}>
                {t('learn')}
              </Text>
            </ExternalLink>
          </Box>
        )}
      </Box>
    );
  }

  function renderPurchasedView() {
    return (
      <BoxPurchased pt={1}>
        <BoxCurrentSub>
          <CardSubscriptionCircle backgroundColor="silverSand" />
          <Box pt={0.75}>
            <Text size={0.875} lineHeight="normal" color="grayChateau">
              {t('currentSubscription')}
            </Text>
          </Box>
        </BoxCurrentSub>
        <Box pt={1}>
          <Link href={ROUTE.ACCOUNT.INDEX}>
            <Text size={0.875} color="turquoise" lineHeight="normal">
              {t('manageSubscription')}
            </Text>
          </Link>
        </Box>
      </BoxPurchased>
    );
  }

  function renderContent(option) {
    const view = subscription.is_purchased
      ? renderPurchasedView()
      : renderNotPurchasedView();

    return (
      <Fragment>
        <CardSubscriptionInfo
          color={textColor}
          name={subscription.name}
          title={option.tooltip_title}
          platforms={option.tooltip_platforms}
          description={option.info}
          tooltip={option.tooltip}
          subtitle={option.subtitle}
        />
        {view}
      </Fragment>
    );
  }

  let label = null;
  if (isRecommendedSubscription) {
    label = {
      backgroundColor: 'turquoise',
      text: recommendedText,
    };
  }

  if (hasCurrentSale) {
    const currentSale = sale as SalesState;
    if (currentSale.saleLabel) {
      label = {
        backgroundColor: 'mulberry',
        text: currentSale.saleLabel,
      };
    }

    if (
      ((currentBundle === OLD_BUNDLE.STUDENT ||
        currentBundle === OLD_BUNDLE.STUDENT) &&
        sale.hideForStudent) ||
      ((currentBundle === OLD_BUNDLE.PRO || currentBundle === OLD_BUNDLE.PRO) &&
        sale.hideForProfessional)
    ) {
      label = null;
    }
  }

  return (
    <StyledBox backgroundColor="white" className={className}>
      {label && <CardSubscriptionLabel {...label} />}
      {isLoadingApps ? <Loader size={5} grow /> : renderContent(option)}
    </StyledBox>
  );
};

export default withTranslation('common')(CardSubscription);

const StyledBox = styled(Box)`
  cursor: default;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 2em;
  padding: 2.5em 1em 1.5em 1em;
  border-radius: 0.25em;
  border: 0.0625em solid ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 16.125em;
  height: 29.125em;
`;

const BoxPurchased = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
`;

const BoxCurrentSub = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatusButtonExtend = styled(StatusButton)`
  font-weight: bold;
`;
